<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />
  <AppSnackBar
    :message="notifyMessage"
    :notificationType="notifyType"
    v-if="isNotify"
  ></AppSnackBar>
  <app-loader v-if="isLoading"></app-loader>
  <div
    class="dashboard-header d-flex justify-content-between align-items-top align-items-lg-center align-items-md-end pb-20"
  >
    <div class="">
      <h2 class="font-28 font-34-sm-big font-w-300">Create Campaign</h2>
      <p class="font-14 color-light2 mb-0">
        Create exciting campaigns to engage with your club members for
        promotions and club activities.
      </p>
    </div>
    <div>
      <kbutton
        :fill-mode="'flat'"
        :class="'pt-0-lg pr-0-lg'"
        @click="doNavigateToCampaignHomepage"
        >Cancel
      </kbutton>
    </div>
  </div>

  <div class="dashboard-content p-0">
    <div class="create-campaign-wrap d-flex justify-content-between">
      <div class="w-50-p">
        <div
          class="create-campaign-left p-48 overflow-y-auto max-height-100-180 min-height-calc-100-180"
        >
          <div class="k-form-field mb-2">
            <label class="font-16">Campaign Name *</label>
            <!-- @keyup="errors.Name[0]=''" -->
            <k-input
              type="text"
              v-model="campaignName"
              placeholder="Enter campaign name"
              aria-required="true"
            >
            </k-input>
            <span class="k-form-error">{{this.errors?.Name?.[0]}}</span>
            <div class="col-md-12 mb-2">
              <label class="" for="">Select Distribution channel</label>
            </div>
            <div class="col-md-12">
              <div class="d-flex justify-content-between mb-3">
                <radiogroup
                  :default-value="dataItems[0].value"
                  :data-items="dataItems"
                  :layout="'horizontal'"
                  :value="distributionChannel"
                  @change="handleChange"
                />
              </div>
            </div>
          </div>
          <p class="font-16">Campaign Editor Type</p>

          <div class="voucher-tab-menu-items mb-5">
            <div
              ref="regularEmail"
              @click="campaignTypeClickHandler(1)"
              class="single active"
            >
              <h4 class="font-19 font-w-400">Regular Email</h4>
              <p class="font-16 color-light2">
                Create basic campaigns using drag and drop editor and analyse
                audience activity.
              </p>
            </div>
            <div
              ref="advancedEmail"
              @click="campaignTypeClickHandler(2)"
              class="single"
            >
              <h4 class="font-19 font-w-400">Advanced Email</h4>
              <p class="font-16 color-light2">
                Create multiple variants of your email and optimize sending
                based on audience activity using multivariate testing.
              </p>
            </div>
          </div>

          <kbutton
            :theme-color="'primary'"
            :disabled="!campaignName"
            :class="'w-100'"
            @click="handleCreateEvent"
            >Continue</kbutton
          >
        </div>
      </div>
      <div class="w-50-p">
        <div
          class="create-campaign-right p-44 h-100 bg-white overflow-y-auto max-height-100-180 min-height-calc-100-180"
        >
          <div
            v-if="this.creationTypeSerial != 4"
            class="p-42 d-flex justify-content-center align-items-center h-100"
          >
            <div class="text-center">
              <div class="campaign-banner">
                <svg
                  width="216"
                  height="206"
                  viewBox="0 0 216 206"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="102.496" cy="103" r="102.496" fill="white" />
                  <circle
                    cx="102.496"
                    cy="103"
                    r="102.496"
                    fill="#0052CC"
                    fill-opacity="0.12"
                  />
                  <g filter="url(#filter0_dd_5732_57606)">
                    <rect
                      x="23.6531"
                      y="22.4048"
                      width="159.438"
                      height="160.314"
                      rx="18"
                      fill="white"
                    />
                  </g>
                  <g opacity="0.9" filter="url(#filter1_d_5732_57606)">
                    <rect
                      x="7.00854"
                      y="30.5039"
                      width="162.066"
                      height="39.4215"
                      rx="10"
                      fill="white"
                    />
                  </g>
                  <rect
                    x="11.2649"
                    y="34.5039"
                    width="30.6612"
                    height="31.5372"
                    rx="8"
                    fill="#A4C3F3"
                  />
                  <rect
                    x="50.8103"
                    y="40.8013"
                    width="91.1074"
                    height="7.8843"
                    rx="3.94215"
                    fill="#0052CC"
                    fill-opacity="0.25"
                  />
                  <rect
                    x="51.9998"
                    y="53.5039"
                    width="47"
                    height="7"
                    rx="3.5"
                    fill="white"
                  />
                  <rect
                    x="51.9998"
                    y="53.5039"
                    width="47"
                    height="7"
                    rx="3.5"
                    fill="#0052CC"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M17.0691 44.8251C17.0691 43.8082 17.4731 42.8329 18.1922 42.1138C18.9112 41.3947 19.8865 40.9907 20.9035 40.9907H32.4066C33.4235 40.9907 34.3988 41.3947 35.1179 42.1138C35.837 42.8329 36.241 43.8082 36.241 44.8251V56.3282C36.241 57.3452 35.837 58.3205 35.1179 59.0395C34.3988 59.7586 33.4235 60.1626 32.4066 60.1626H20.9035C19.8865 60.1626 18.9112 59.7586 18.1922 59.0395C17.4731 58.3205 17.0691 57.3452 17.0691 56.3282V44.8251Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.3001 49.6182C24.6236 49.6182 25.6965 48.5452 25.6965 47.2217C25.6965 45.8981 24.6236 44.8252 23.3001 44.8252C21.9765 44.8252 20.9036 45.8981 20.9036 47.2217C20.9036 48.5452 21.9765 49.6182 23.3001 49.6182Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M29.0765 51.1718L20.9036 60.1625H32.5342C33.5173 60.1625 34.4602 59.7719 35.1554 59.0768C35.8505 58.3816 36.2411 57.4387 36.2411 56.4556V56.3281C36.2411 55.8814 36.0733 55.7098 35.7714 55.3791L31.9082 51.1661C31.7282 50.9697 31.5092 50.813 31.2652 50.7059C31.0212 50.5989 30.7576 50.5439 30.4911 50.5444C30.2247 50.545 29.9613 50.601 29.7177 50.7091C29.4742 50.8171 29.2558 50.9747 29.0765 51.1718V51.1718Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g opacity="0.9" filter="url(#filter2_d_5732_57606)">
                    <rect
                      x="7.00854"
                      y="135.413"
                      width="162.066"
                      height="39.4215"
                      rx="10"
                      fill="white"
                    />
                  </g>
                  <rect
                    x="11.2649"
                    y="139.793"
                    width="30.6612"
                    height="30.6612"
                    rx="8"
                    fill="#A4C3F3"
                  />
                  <rect
                    opacity="0.5"
                    x="50.9998"
                    y="145.504"
                    width="29"
                    height="7"
                    rx="3.5"
                    fill="#78A6ED"
                  />
                  <rect
                    x="50.8103"
                    y="157.314"
                    width="78.843"
                    height="7.8843"
                    rx="3.94215"
                    fill="white"
                  />
                  <rect
                    x="50.8103"
                    y="157.314"
                    width="78.843"
                    height="7.8843"
                    rx="3.94215"
                    fill="#0052CC"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M17.0691 149.533C17.0691 148.516 17.4731 147.541 18.1922 146.822C18.9112 146.103 19.8865 145.699 20.9035 145.699H32.4066C33.4235 145.699 34.3988 146.103 35.1179 146.822C35.837 147.541 36.241 148.516 36.241 149.533V161.036C36.241 162.053 35.837 163.028 35.1179 163.748C34.3988 164.467 33.4235 164.871 32.4066 164.871H20.9035C19.8865 164.871 18.9112 164.467 18.1922 163.748C17.4731 163.028 17.0691 162.053 17.0691 161.036V149.533Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.3001 154.326C24.6236 154.326 25.6965 153.253 25.6965 151.93C25.6965 150.606 24.6236 149.533 23.3001 149.533C21.9765 149.533 20.9036 150.606 20.9036 151.93C20.9036 153.253 21.9765 154.326 23.3001 154.326Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M29.0765 155.88L20.9036 164.87H32.5342C33.5173 164.87 34.4602 164.48 35.1554 163.785C35.8505 163.09 36.2411 162.147 36.2411 161.164V161.036C36.2411 160.589 36.0733 160.418 35.7714 160.087L31.9082 155.874C31.7282 155.678 31.5092 155.521 31.2652 155.414C31.0212 155.307 30.7576 155.252 30.4911 155.252C30.2247 155.253 29.9613 155.309 29.7177 155.417C29.4742 155.525 29.2558 155.683 29.0765 155.88V155.88Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g filter="url(#filter3_d_5732_57606)">
                    <rect
                      x="49.9341"
                      y="83.7271"
                      width="162.066"
                      height="39.4215"
                      rx="10"
                      fill="white"
                    />
                    <rect
                      x="50.4341"
                      y="84.2271"
                      width="161.066"
                      height="38.4215"
                      rx="9.5"
                      stroke="#0052CC"
                      stroke-opacity="0.5"
                    />
                  </g>
                  <rect
                    x="54.1902"
                    y="88.1074"
                    width="30.6612"
                    height="30.6612"
                    rx="8"
                    fill="url(#paint0_linear_5732_57606)"
                  />
                  <rect
                    x="92.8599"
                    y="94.2393"
                    width="91.9835"
                    height="7.00826"
                    rx="3.50413"
                    fill="#0052CC"
                    fill-opacity="0.25"
                  />
                  <rect
                    x="92.9998"
                    y="105.504"
                    width="44"
                    height="8"
                    rx="4"
                    fill="white"
                  />
                  <rect
                    x="92.9998"
                    y="105.504"
                    width="44"
                    height="8"
                    rx="4"
                    fill="#0052CC"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M59.8376 97.9164C59.8376 96.8995 60.2416 95.9242 60.9607 95.2051C61.6798 94.486 62.6551 94.082 63.672 94.082H75.1752C76.1921 94.082 77.1674 94.486 77.8865 95.2051C78.6056 95.9242 79.0095 96.8995 79.0095 97.9164V109.42C79.0095 110.436 78.6056 111.412 77.8865 112.131C77.1674 112.85 76.1921 113.254 75.1752 113.254H63.672C62.6551 113.254 61.6798 112.85 60.9607 112.131C60.2416 111.412 59.8376 110.436 59.8376 109.42V97.9164Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M66.0686 102.709C67.3921 102.709 68.4651 101.637 68.4651 100.313C68.4651 98.9894 67.3921 97.9165 66.0686 97.9165C64.7451 97.9165 63.6721 98.9894 63.6721 100.313C63.6721 101.637 64.7451 102.709 66.0686 102.709Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M71.8451 104.263L63.6721 113.254H75.3027C76.2859 113.254 77.2287 112.863 77.9239 112.168C78.6191 111.473 79.0096 110.53 79.0096 109.547V109.419C79.0096 108.973 78.8419 108.801 78.5399 108.47L74.6768 104.257C74.4967 104.061 74.2777 103.904 74.0337 103.797C73.7897 103.69 73.5261 103.635 73.2597 103.636C72.9932 103.636 72.7298 103.692 72.4863 103.8C72.2427 103.908 72.0243 104.066 71.8451 104.263V104.263Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M79.285 138.392L74.8804 121.818C74.487 120.337 75.8354 118.982 77.3176 119.369L94.0553 123.73C95.5553 124.121 96.0597 125.996 94.9583 127.087L93.3338 128.695C92.5435 129.478 92.5435 130.755 93.3338 131.538L99.7217 137.863C100.512 138.646 100.512 139.923 99.7217 140.705L97.0092 143.391C96.2298 144.163 94.9741 144.163 94.1947 143.391L87.7511 137.011C86.9717 136.239 85.716 136.239 84.9366 137.011L82.6252 139.3C81.533 140.381 79.6798 139.878 79.285 138.392Z"
                    fill="white"
                    stroke="#0052CC"
                    stroke-width="1.5"
                  />
                  <defs>
                    <filter
                      id="filter0_dd_5732_57606"
                      x="18.6531"
                      y="20.4048"
                      width="169.438"
                      height="171.314"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5732_57606"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_5732_57606"
                        result="effect2_dropShadow_5732_57606"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_5732_57606"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter1_d_5732_57606"
                      x="3.00854"
                      y="29.5039"
                      width="170.066"
                      height="47.4214"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feMorphology
                        radius="1"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow_5732_57606"
                      />
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5732_57606"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5732_57606"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter2_d_5732_57606"
                      x="3.00854"
                      y="134.413"
                      width="170.066"
                      height="47.4214"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feMorphology
                        radius="1"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow_5732_57606"
                      />
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5732_57606"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5732_57606"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter3_d_5732_57606"
                      x="45.9341"
                      y="82.7271"
                      width="170.066"
                      height="47.4214"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feMorphology
                        radius="1"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow_5732_57606"
                      />
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5732_57606"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5732_57606"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_5732_57606"
                      x1="69.5208"
                      y1="88.1074"
                      x2="80.9092"
                      y2="118.769"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0052CC" />
                      <stop offset="1" stop-color="#A4C3F3" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <p class="text-center color-gray mt-20 mb-0">
                Create an email using our drag-and-drop editor.
              </p>
            </div>
          </div>

          <div v-if="this.creationTypeSerial === 4" class="p-42"></div>

          <div
            v-if="[3,4].includes(this.creationTypeSerial)"
            class="create-voucher-slide-group d-flex justify-content-center mt-5"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
